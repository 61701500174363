/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap-custom"; // for customization bootstrap

// Core variables and mixins
@import "vendors/bootstrap/variables";
@import "vendors/bootstrap/mixins";

// Reset and dependencies
@import "vendors/bootstrap/normalize";
@import "vendors/bootstrap/print";
@import "vendors/bootstrap/glyphicons";

// Core CSS
@import "vendors/bootstrap/scaffolding";
@import "vendors/bootstrap/type";
@import "vendors/bootstrap/code";
@import "vendors/bootstrap/grid";
@import "vendors/bootstrap/tables";
@import "vendors/bootstrap/forms";
@import "vendors/bootstrap/buttons";

// Components
 @import "vendors/bootstrap/component-animations";
 @import "vendors/bootstrap/dropdowns";
// @import "vendors/bootstrap/button-groups";
// @import "vendors/bootstrap/input-groups";
 @import "vendors/bootstrap/navs";
 @import "vendors/bootstrap/navbar";
// @import "vendors/bootstrap/breadcrumbs";
// @import "vendors/bootstrap/pagination";
// @import "vendors/bootstrap/pager";
// @import "vendors/bootstrap/labels";
// @import "vendors/bootstrap/badges";
// @import "vendors/bootstrap/jumbotron";
// @import "vendors/bootstrap/thumbnails";
// @import "vendors/bootstrap/alerts";
// @import "vendors/bootstrap/progress-bars";
// @import "vendors/bootstrap/media";
// @import "vendors/bootstrap/list-group";
// @import "vendors/bootstrap/panels";
 @import "vendors/bootstrap/responsive-embed";
// @import "vendors/bootstrap/wells";
// @import "vendors/bootstrap/close";

// Components w/ JavaScript
// @import "vendors/bootstrap/modals";
// @import "vendors/bootstrap/tooltip";
// @import "vendors/bootstrap/popovers";
// @import "vendors/bootstrap/carousel";

// Utility classes
 @import "vendors/bootstrap/utilities";
 @import "vendors/bootstrap/responsive-utilities";
